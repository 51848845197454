import React from 'react'
import Img from "gatsby-image"
import Grid from "@material-ui/core/Grid"

const Card = ({image, title, text, position}) => {
	return (
		<Grid item className={`card-container-${position}`} xs={12}>
			{image && 
			(<div className="card-picto-wrapper">
				<Img fluid={image} />
			</div>)}
			<div className={`card-text-wrapper-${position}`}>
				<h2 className="card-title">{title}</h2>
				<p className="card-text">{text}</p>
				<div className="activ-underline"></div>
			</div>
		</Grid>
	)
}

export default Card