import React from 'react'
import BackgroundImage from 'gatsby-background-image'

const ImageLayout = ({text, image, filtered}) => {
	return (
		<BackgroundImage
			Tag="div"
			id={filtered ? "image-layout-header-filtered" : "image-layout-header"}
			fluid={image}
			>
			<h1 id="page-title">{text}</h1>
		</BackgroundImage>
	)
}

export default ImageLayout